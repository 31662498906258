import React, { useContext } from "react";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  AnchorButton,
  Button,
  Menu,
  Navbar,
  NavbarGroup,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core";
import HeaderMenu from "./header-menu";
import { StoresContext } from "../../pages/_app";
import { Popover2 } from "@blueprintjs/popover2";

const GenomesMenu = () => {
  const items: [string, string][] = [
    ["/genomes", "Complete Collection"],
    ["/genomes/bacteriology", "Bacteriology Collection"],
    ["/genomes/mycology", "Mycology Collection"],
    ["/genomes/protistology", "Protistology Collection"],
    ["/genomes/virology", "Virology Collection"],
  ];
  return (
    <Menu>
      {items.map(([href, label]) => (
        // Can't use `MenuItem` from blueprintjs. Simple <a> is not good enough.
        // `Link` from needs to be used otherwise we get page refreshes instead of
        // next.js navigation.
        <li key={href}>
          <Link className="bp4-menu-item" role="menuitem" href={href}>
            {label}
          </Link>
        </li>
      ))}
    </Menu>
  );
};

const DocumentationMenu = () => {
  const items: [string, string][] = [
    [
      "https://docs.onecodex.com/en/collections/2314023-the-atcc-genome-portal",
      "Materials and Methods",
    ],
    ["https://docs.onecodex.com/en/articles/5812163-atcc-genome-portal-api-guide", "REST API"],
    ["https://github.com/ATCC-Bioinformatics", "Visit us on GitHub"],
    [
      "https://docs.onecodex.com/en/articles/7905448-how-to-cite-the-atcc-genome-portal",
      "How to Cite the ATCC Genome Portal",
    ],
  ];
  return (
    <Menu>
      {items.map(([href, label]) => (
        <li key={href}>
          <Link className="bp4-menu-item" role="menuitem" href={href}>
            {label}
          </Link>
        </li>
      ))}
    </Menu>
  );
};

const AppHeader = () => {
  const rootStore = useContext(StoresContext);
  const username = rootStore.userStore.getUsername();
  const router = useRouter();
  const showSubscribeButton =
    !rootStore.userStore.hasActiveSubscription() &&
    router.pathname !== "/plans" &&
    router.pathname !== "/checkout-success" &&
    router.pathname !== "/checkout-in-progress" &&
    router.pathname !== "/checkout-in-review";

  return (
    <Navbar className="navbar">
      <a target="_blank" rel="noopener noreferrer" href="https://www.atcc.org">
        <span className="navbar-brand">
          <img src="/static/atcc/logo-highres.png" alt="ATCC logo" className="brand-logo" />
        </span>
      </a>
      <Link href="/">
        <span className="nav-item">HOME</span>
      </Link>
      <Popover2
        interactionKind={PopoverInteractionKind.HOVER}
        popoverClassName="nav-item-genomes-submenu"
        minimal={true}
        position={Position.BOTTOM}
        canEscapeKeyClose={true}
        content={<GenomesMenu />}
        className="nav-item"
      >
        <a className="nav-item nav-header-text">GENOMES</a>
      </Popover2>
      <Link href="/sequence-search">
        <span className="nav-item">SEQUENCE SEARCH</span>
      </Link>
      <Popover2
        interactionKind={PopoverInteractionKind.HOVER}
        popoverClassName="nav-item-documentation-submenu"
        minimal={true}
        position={Position.BOTTOM}
        canEscapeKeyClose={true}
        content={<DocumentationMenu />}
        className="nav-item"
      >
        <a className="nav-item nav-header-text">DOCUMENTATION</a>
      </Popover2>
      <NavbarGroup className="navbar-profile">
        {showSubscribeButton && (
          <Link href="/plans" passHref legacyBehavior>
            <AnchorButton intent="primary" className="subscribe-button">
              Become a Supporting Member
            </AnchorButton>
          </Link>
        )}
        <Popover2
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.BOTTOM}
          canEscapeKeyClose={true}
          content={<HeaderMenu />}
          minimal={true}
        >
          <Button text={username.toUpperCase()} icon="person" minimal />
        </Popover2>
      </NavbarGroup>
    </Navbar>
  );
};

export default observer(AppHeader);

import {
  action,
  computed,
  IObservableArray,
  observable,
  runInAction,
  toJS,
  makeObservable,
} from "mobx";
import {
  getDefaultSearchObject,
  SEARCH_SECTIONS,
  SearchFilterKeys,
  SearchFilterProps,
  SearchFilterValue,
} from "../utils/search";

import {
  PaginationMetadata,
  GenomeInList,
  GenomeStarred,
  DownloadLink,
  CollectionName,
  ApiClient,
  GenomesOrderBy,
  GenomePublicationChange,
  AnnotationsCsvExportResult,
} from "../api";
import { AppToaster } from "../components/toaster";
import { Intent } from "@blueprintjs/core";
import { logEvent, trackSegmentEvent } from "../utils/analytics";
import { downloadFileWithRetry } from "../utils/download-file";

export enum DownloadKind {
  Assembly = "assembly",
  Annotations = "annotations",
}

export interface DownloadData {
  kind: DownloadKind;
  genomeName: string;
  genomeUuid: string;
  assemblyUuid: string;
  context: string;
  annotationSetUuid?: string;
}

interface FetchGenomes {
  mineOnly: boolean;
}

interface TabData {
  genomes: IObservableArray<GenomeInList>;
  loading: boolean;
  pagination: PaginationMetadata;
  error: boolean;
}

interface PayloadData {
  uuid: string;
  is_atcc: true;
  lot_id?: string;
}

function getDefaultData(): { all: TabData; my: TabData } {
  return {
    all: {
      genomes: observable.array([]),
      loading: false,
      pagination: { page: 1 },
      error: false,
    },
    my: {
      genomes: observable.array([]),
      loading: false,
      pagination: { page: 1 },
      error: false,
    },
  };
}

const DEFAULT_ORDER_BY = "taxon.name";
const FALSY_VALUES = ["0", "false", "f", "no", "n"];

export default class GenomeStore {
  public api: ApiClient;
  public comparing = observable<string>([]);
  public hydrated = false;

  @observable public data = getDefaultData();
  @observable searchObject = getDefaultSearchObject();
  @observable collectionName: CollectionName = null;
  @observable orderBy: GenomesOrderBy = DEFAULT_ORDER_BY;
  @observable ascending = true;
  @observable lotId = "";

  constructor(api: ApiClient, ssrData?: GenomeStore) {
    makeObservable(this);

    this.api = api;
    if (ssrData) {
      this.hydrated = ssrData.hydrated;

      runInAction(() => {
        for (const key of Object.keys(ssrData.searchObject) as SearchFilterKeys[]) {
          if (key === "tags") {
            this.searchObject.tags = observable.array(ssrData.searchObject.tags);
          } else {
            const k = key as Exclude<SearchFilterKeys, "tags">;
            this.searchObject[k] = ssrData.searchObject[k];
          }
        }

        this.data = getDefaultData();
        this.orderBy = ssrData.orderBy;
        this.collectionName = ssrData.collectionName;
        this.ascending = ssrData.ascending;

        this.data.all.genomes = observable.array(ssrData.data.all.genomes);
        this.data.all.pagination = ssrData.data.all.pagination;
        this.data.all.loading = false;
        this.data.all.error = ssrData.data.all.error;

        this.data.my.error = ssrData.data.my.error;
        if (ssrData.data.my.genomes.length > 0 || !ssrData.data.my.error) {
          this.data.my.genomes = observable.array(ssrData.data.my.genomes);
          this.data.my.pagination = ssrData.data.my.pagination;
          this.data.my.loading = false;
        }
      });
    }
  }

  updateIfNotHydrated = (collectionName: CollectionName, params: { [key: string]: unknown }) => {
    if (this.hydrated) {
      // Data already updated via SSR. But next time we will need to fetch it.
      // So marking itself as non-hydrated.
      this.hydrated = false;
    } else {
      this.setVariablesFromQuery(params);
      this.setCollectionName(collectionName);
      this.fetchAllGenomes();
    }
  };

  // Only set in the all page
  @action
  setVariablesFromQuery = (params: { [key: string]: unknown }) => {
    if (Object.keys(params).length === 0) {
      this.searchObject = getDefaultSearchObject();
    }

    for (const section of SEARCH_SECTIONS) {
      const val = params[section.key];
      if (!val) {
        continue;
      }

      if (section.key === "tags") {
        if (Array.isArray(val)) {
          this.searchObject.tags.replace(val as any);
        } else {
          this.searchObject.tags.push(val as any);
        }
      } else if (section.key === "type_strain") {
        // @ts-ignore
        this.searchObject[section.key] = !FALSY_VALUES.includes(val.toLowerCase());
      } else {
        // @ts-ignore
        this.searchObject[section.key] = val;
      }
    }

    const orderBy = params["orderBy"];
    const ascending = params["ascending"];
    const page = params["page"] as string;

    this.orderBy = (orderBy || this.orderBy) as GenomesOrderBy;
    this.ascending = ascending ? ascending === "true" : this.ascending;
    this.data.all.pagination.page = page ? parseInt(page, 10) : 1;
  };

  @action
  fetchGenomes = async (opts: FetchGenomes): Promise<void> => {
    const key = opts.mineOnly ? "my" : "all";
    this.data[key].loading = true;
    const page = this.data[key].pagination.page || 1;
    const payload = {
      order_by: this.orderBy,
      ascending: this.ascending,
      collection_name: this.collectionName,
      search_object: toJS(this.searchObject),
      mine_only: opts.mineOnly,
    };

    const result = await this.api.post<GenomeInList[]>(
      "/genome-portal-api/genomes",
      payload,
      {},
      { page: page }
    );

    runInAction(() => {
      this.data[key].loading = false;

      if (result.error !== undefined) {
        this.data[key].error = true;
        this.data[key].genomes.clear();
      } else {
        this.data[key].error = false;
        this.data[key].genomes.replace(result.data);
        this.data[key].pagination = JSON.parse(result.headers.get("X-Pagination")!);
      }
    });
  };

  fetchAllGenomes = (): Promise<unknown> => {
    return Promise.all([
      this.fetchGenomes({ mineOnly: true }),
      this.fetchGenomes({ mineOnly: false }),
    ]);
  };

  fetchHistory = async (uuid: string): Promise<GenomePublicationChange[]> => {
    const resp = await this.api.get<GenomePublicationChange[]>(
      "/genome-portal-api/genome-history/{uuid}",
      { uuid }
    );
    if (resp.error === undefined) {
      return resp.data;
    }
    return [];
  };

  @action
  setOrderBy = (mineOnly: boolean, orderBy: GenomesOrderBy, toggleAscending = false) => {
    this.orderBy = orderBy;
    if (toggleAscending) {
      this.ascending = !this.ascending;
    }
    this.fetchGenomes({ mineOnly });
  };

  @action
  removeSearchFilter = (key: SearchFilterKeys, val: SearchFilterValue): void => {
    if (key === "tags") {
      if (val) {
        this.searchObject.tags.remove(val as string);
      }
    } else {
      this.searchObject[key] = null;
    }
    this.data.all.pagination.page = 1;
    this.fetchAllGenomes();
  };

  @action
  addSearchFilter = (key: SearchFilterKeys, val: SearchFilterValue): void => {
    if (key === "tags") {
      if (this.searchObject.tags.indexOf(val as string) === -1) {
        this.searchObject.tags.push(val as string);
      }
    } else if (key === "type_strain") {
      // @ts-ignore
      this.searchObject[key] = !FALSY_VALUES.includes(val.toLowerCase());
    } else {
      // Not sure why TS is complaining here
      // @ts-ignore
      this.searchObject[key] = val;
    }
    this.data.all.pagination.page = 1;
    this.fetchAllGenomes();
  };

  @computed
  get inUseSearchFields(): SearchFilterProps[] {
    const searchFields = [];

    for (const section of SEARCH_SECTIONS) {
      const val = this.searchObject[section.key];
      if (val === null || val === undefined) {
        continue;
      }

      if (section.key === "tags") {
        for (const tag of val) {
          section.val = tag;
          searchFields.push(section);
        }
      } else {
        section.val = val;
        searchFields.push(section);
      }
    }

    return searchFields;
  }

  @action
  setPage = (mineOnly: boolean, page: number) => {
    const key = mineOnly ? "my" : "all";
    this.data[key].pagination.page = page;
    this.fetchGenomes({ mineOnly });
  };

  @action
  toggleComparingUuid = (uuid: string) => {
    if (this.comparing.find((u) => uuid === u)) {
      this.comparing.remove(uuid);
    } else {
      if (this.comparing.length < 4) {
        this.comparing.push(uuid);
      } else {
        console.log("Cannot compare >4 genomes.");
      }
    }
  };

  getAnnotationsLinkInfo = async (annotationSetUuid: string): Promise<DownloadLink | null> => {
    const payload: PayloadData = { uuid: annotationSetUuid, is_atcc: true };
    if (this.lotId) {
      payload.lot_id = this.lotId;
    }
    const resp = await this.api.post<DownloadLink>("/genome-portal-api/annotations-link", payload);
    if (resp.error === undefined) {
      return resp.data;
    }
    return null;
  };

  getAssemblyLinkInfo = async (assemblyUuid: string): Promise<DownloadLink | null> => {
    const payload: PayloadData = { uuid: assemblyUuid, is_atcc: true };
    if (this.lotId) {
      payload.lot_id = this.lotId;
    }
    const resp = await this.api.post<DownloadLink>("/genome-portal-api/assembly-link", payload);
    if (resp.error === undefined) {
      return resp.data;
    }
    return null;
  };

  formatGenomeName(genomeName: string, searchTerm: string): string {
    let filename = genomeName.replace(" ", "_").replace("®", "").replace("™", "");
    if (searchTerm) {
      filename = filename + "_" + searchTerm;
    }
    return filename;
  }

  exportToCSV(filename: string, rows: BlobPart) {
    const BOM = "\ufeff";
    const blob = new Blob([BOM + rows], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${filename}.csv`, true);
  }

  async downloadAnnotationsCSV(
    genomeName: string,
    searchTerm: string,
    annotationUuids: string[],
    genomeUuid?: string,
    assemblyUuid?: string
  ): Promise<string | null> /* error msg or null */ {
    AppToaster!.show({
      message: "Preparing your download...",
      intent: Intent.PRIMARY,
    });

    AppToaster!.show({
      message: "Downloading",
      intent: Intent.PRIMARY,
    });

    const formattedGenomeName = this.formatGenomeName(genomeName, searchTerm);
    logEvent("annotations", "download", genomeName);
    trackSegmentEvent("Download Annotations", {
      genomeName: formattedGenomeName,
      assemblyUuid: assemblyUuid,
      genomeUuid: genomeUuid,
      context: "Annotations Viewer",
      format: "csv",
    });

    const response = await this.api.post<AnnotationsCsvExportResult>(
      "/genome-portal-api/annotations-csv-export",
      { uuids: annotationUuids }
    );

    if (response.error === undefined) {
      this.exportToCSV(`${genomeName}.csv`, response.data.data);
      return null;
    } else {
      return `Could not download the annotations, please try again later.`;
    }
  }

  async downloadFile(data: DownloadData): Promise<string | null> /* error msg or null */ {
    const msgKey =
      AppToaster &&
      AppToaster.show({
        message: `Preparing your ${data.kind} download...`,
        intent: Intent.PRIMARY,
      });

    let linkInfo: DownloadLink | null = null;
    switch (data.kind) {
      case DownloadKind.Annotations: {
        linkInfo = await this.getAnnotationsLinkInfo(data.annotationSetUuid!);
        break;
      }
      case DownloadKind.Assembly: {
        linkInfo = await this.getAssemblyLinkInfo(data.assemblyUuid);
        break;
      }
    }

    if (!linkInfo) {
      AppToaster && AppToaster.dismiss(msgKey!);
      if (this.lotId) {
        return "The lot number you entered does not match the genome you are trying to download. Please check your number, or contact tech@atcc.org.";
      } else {
        return `Could not download the ${data.kind}, please try again later.`;
      }
    }

    const format = data.kind === DownloadKind.Assembly ? "fasta" : "gbk";
    logEvent(format, "download", data.genomeName);
    trackSegmentEvent(`Download ${data.kind}`, {
      genomeName: data.genomeName,
      genomeUuid: data.genomeUuid,
      assemblyUuid: data.assemblyUuid,
      context: data.context,
      format,
    });
    try {
      await downloadFileWithRetry(linkInfo.url, linkInfo.save_as_filename);
      return null;
    } catch {
      return `Could not download the ${data.kind}, please try again later.`;
    }
  }

  @action
  setCollectionName = (collectionName: CollectionName) => {
    this.collectionName = collectionName;
  };

  @action
  updateGenomeStarred = (starred: boolean, uuid: string) => {
    const genome = this.data.all.genomes.find((g) => g.uuid === uuid);
    if (!genome) {
      return;
    }
    genome.starred = starred;
    const myGenomes = this.data.my.genomes.filter((g) => g.uuid !== uuid);
    if (starred) {
      myGenomes.push({ ...genome });
    }
    this.data.my.genomes.replace(myGenomes);
    this.data.my.pagination.total = this.data.my.genomes.length;
  };

  @action
  setLotId = (newLotId: string) => {
    this.lotId = newLotId;
  };

  toggleGenomeStarring = async (starred: boolean, uuid: string): Promise<void> => {
    const resp = await this.api.patch<GenomeStarred>(
      "/genome-portal-api/genomes/{uuid}",
      { starred },
      { uuid }
    );
    if (resp.error === undefined) {
      this.updateGenomeStarred(resp.data.starred, uuid);
    }
  };

  get comparisonUrl(): string {
    const query = "annotation_set=" + this.comparing.join("&annotation_set=");
    return `/genomes/compare?${query}`;
  }

  @computed
  get canCompare(): boolean {
    return this.comparing.length >= 2;
  }

  @computed
  get urlParams(): string {
    const params = new URLSearchParams();
    if (this.orderBy !== DEFAULT_ORDER_BY) {
      params.set("orderBy", this.orderBy);
    }

    if (!this.ascending) {
      params.set("ascending", "false");
    }

    // Pages are 1-indexed
    const page = this.data.all.pagination.page || 1;
    if (page > 1) {
      params.set("page", page.toString(10));
    }

    // Put search args at root level of the url
    for (const section of SEARCH_SECTIONS) {
      const val = this.searchObject[section.key];
      if (!val) {
        continue;
      }
      if (section.key === "tags") {
        for (const tag of val) {
          params.set(section.key, tag);
        }
      } else {
        params.set(section.key, val as any);
      }
    }
    return params.toString();
  }
}
